import { defineStore } from 'pinia'
import { Router } from 'vue-router'

import { getMyBizList } from '@/api/cmdb'
import { getDashboards } from '@/api/monitor'
import { MOA_AUTH_INFO } from '@/config/cache'
import { Platform } from '@/types/store'
import { getBizIdFromUrl } from '@/utils'
import { clearAuthCache, getAuthCache, setAuthCache } from '@/utils/auth'
import { webCookie } from '@/utils/cache'

import { store } from '../index'
import { useMultipleTabStore } from './multipleTab'

interface BusinessRoute {
    id: number
    name: string
    uid: string
    bizId: number
    bizCode: string
}

interface PlatformStore {
    platform?: number
    platformList: Platform[]
    businessRoutes: BusinessRoute[]
}

export const usePlatformStore = defineStore({
    id: 'app-platform',
    state: (): PlatformStore => ({
        platform: undefined,
        platformList: [],
        businessRoutes: []
    }),
    getters: {
        getPlatform(): number {
            return 1
        },
        getPlatformCode(): string {
            return ''
        },
        getPlatformName(): string {
            return ''
        },
        getPlatforms(): Platform[] {
            return this.platformList
        },
        getBusinessRoutes(): BusinessRoute[] {
            return this.businessRoutes
        }
    },
    actions: {
        getPlatformCodeById(id: number): string {
            return ''
        },
        getPlatformNameById(id: number): string {
            return ''
        },
        async getPlatformList(router: Router) {
            let list: any[] = []
            this.platformList = list.filter((item) => item.status === 0)
            await this.setPlatform(1, router)
        },
        async setPlatform(id: number, router: Router) {
            this.platform = id
            await this.initBusinessRoutes(router)
            // setAuthCache(PLATFORM_KEY, id)
            window.GLOBAL_ID = 1
            window.GLOBAL_CODE = ''
            window.GLOBAL_NAME = ''
        },
        async setPlatformPlus(id: number, router: Router) {
            this.platform = id
            // setAuthCache(PLATFORM_KEY, id)
            console.log('setPlatform: ', id)
            window.GLOBAL_ID = id
            window.GLOBAL_CODE = this.platformList.find((item) => item.id === id)?.code
            window.GLOBAL_NAME = this.platformList.find((item) => item.id === id)?.name
            console.log('setPlatform1111: ', window.GLOBAL_ID, window.GLOBAL_CODE)

        },
        addBusinessRoutesHead(data: BusinessRoute) {
            this.businessRoutes.unshift(data)
        },
        addBusinessRoutes(data: BusinessRoute) {
            this.businessRoutes.push(data)
        },
        updataBusinessRoutes(data: BusinessRoute) {
            const index = this.businessRoutes.findIndex((item) => item.id === data.id)
            this.businessRoutes[index] = data
        },
        removeBusinessRoutes(id: number) {
            const index = this.businessRoutes.findIndex((item) => item.id === id)
            this.businessRoutes.splice(index, 1)
        },
        async initBusinessRoutes(router: Router) {
            const multipleTabStore = useMultipleTabStore()
            this.businessRoutes = []

            // 这里清理业务路由标识的标签
            const removeTabs = multipleTabStore.getTabList.filter((item) => item.name === 'BusinessMonitor')
            removeTabs.forEach((item) => multipleTabStore.closeTab(item, router))
        }
    }
})

export function usePlatformStoreWithOut() {
    return usePlatformStore(store)
}
